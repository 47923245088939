import { Box } from "@mui/material";
import React from "react";
import { DescriptionForm, TitleForm } from "../atoms/ConsultingFormAtoms";

export function ConsultingFormHeader(){
    return(
        <Box>
            <TitleForm />
            <DescriptionForm />
        </Box>
    )
}