export function calcBudget(income: number, option: any) {

    const result = {
        income: '',
        rule: '',
        needsValue: 0,
        wantsValue: 0,
        savingsValue: 0,
        rentalValue: 0,
        savingsYear: 0,
        savingsFiveYears: 0,
        savingsTenYears: 0,
        code: '',

        needsToString: '',
        wantsToString: '',
        savingsToString: '',
        rentalValueToString: '',

        savingsYearString: '',
        savingsFiveYearsString: '',
        savingsTenYearsString: '',
    };
    const RENTAL_PERCENTAGE = 0.35; // Todo

    result.needsValue = +(option.needs * income).toFixed(2);
    result.wantsValue = +(option.wants * income).toFixed(2);
    result.savingsValue = +(option.savings * income).toFixed(2);

    result.rentalValue = +(RENTAL_PERCENTAGE * income).toFixed(2);
    result.savingsYear = +(result.savingsValue*12).toFixed(2);
    result.savingsFiveYears = +(result.savingsValue*60).toFixed(2);
    result.savingsTenYears = +(result.savingsValue*120).toFixed(2);

    return result;
}

export function generateFV(rate: number, value: number, nper: number){
    var Finance = require("tvm-financejs");
    var finance = new Finance();

    return finance.FV((rate/100/12),nper,-value,0).toFixed(2);
}

export function formatCurrency(currencyType: string, value: number){
    return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: currencyType }).format( value, ).toString();
}