import { Box, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { BudgetAllocationListOrganismProps, BudgetGuidelinesProps, ChangeLanguageGridForHomeProps, GeneralDescProps, InvestmentResultProps, SavingDescProps } from "./interfaces/IConsultingFormOrganisms";
import { FormRateInput } from "../atoms/ConsultingFormAtoms";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HomeIcon from '@mui/icons-material/Home';
import MoneyIcon from '@mui/icons-material/Money';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import { t } from "i18next";
import InfoIcon from '@mui/icons-material/Info';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';

export const ChangeLanguageGridForHome: React.FC<ChangeLanguageGridForHomeProps> = ({
  isWideScreen,
  currentLanguage,
  handleChangeLanguage,
}) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isDesktop || isTablet?  'row' : 'column',
        justifyContent: isDesktop || isTablet ? 'center' : 'flex-start',
        alignItems: 'center',
        marginTop: isWideScreen ? '30px' : '10px',
        gap: '10px',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Select
          value={currentLanguage.substring(0, 2)}
          onChange={handleChangeLanguage}
          sx={{ marginRight: '10px' }}
        >
          <MenuItem value="en">
            <Box component="img" src="https://flagcdn.com/24x18/us.png" sx={{ width: "24", height: "16" }} alt='flag' /> <Box component="a" sx={{paddingLeft: '5px'}}> {t('footer.english')} </Box>
          </MenuItem>
          <MenuItem value="es">
            <Box component="img" src="https://flagcdn.com/24x18/es.png" sx={{ width: "24", height: "16" }} alt='flag' /> <Box component="a" sx={{paddingLeft: '5px'}}> {t('footer.spanish')} </Box>
          </MenuItem>
        </Select>
        
      </Box>
    </Box>
  )
}

export const BudgetAllocationListOrganism: React.FC<BudgetAllocationListOrganismProps> = ({
  needs,
  wants,
  savings
}) => {
  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <MonetizationOnIcon />
        </ListItemIcon>
        <ListItemText primary={t('consultingForm.results.allocate') + " " +
          needs + " " + t('consultingForm.results.needs')} />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary={t('consultingForm.results.use') + " " + wants
          + " " + t('consultingForm.results.personalExpenses')} />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <AccountBalanceIcon />
        </ListItemIcon>
        <ListItemText primary={t('consultingForm.results.setAside') + " " +
          savings + " " + t('consultingForm.results.saving')} />
      </ListItem>
    </List>
  );
};

export const BudgetGuidelines: React.FC<BudgetGuidelinesProps> = ({
  rentalValue,
  needs,
  wants
}) => {
  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary={t('consultingForm.results.rentalTitle') + " "  +
          rentalValue + ", " + t('consultingForm.results.rentalTitle2')} />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <MoneyIcon />
        </ListItemIcon>
        <ListItemText primary={t('consultingForm.results.clarification') + " " +
          needs + " " + t('consultingForm.results.clarification2')} />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <EmojiObjectsIcon />
        </ListItemIcon>
        <ListItemText primary={t('consultingForm.results.wantsClarification') + " " +
          wants + " " + t('consultingForm.results.wantsClarification2')} />
      </ListItem>
    </List>
  );
};

export const GeneralDesc: React.FC<GeneralDescProps> = ({
  income,
  rule
}) => {
  const { i18n } = useTranslation();
  const text = i18n.language === 'es' ? t('consultingForm.results.ruleDesc') + ' ' + rule : rule + ' ' + t('consultingForm.results.ruleDesc');
  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText primary={(t('consultingForm.results.mainDesc') + ' ' + income + ', ' + t('consultingForm.results.the') + ' ' + text) + ' '
          + t('consultingForm.results.result')} />
      </ListItem>
    </List>
  )
}

export const SavingDesc:React.FC<SavingDescProps> = ({
  savings,
  savingYear,
  savingFiveYears,
  savingTenYears 
}) => {
  return (
    <>
      <Box sx={{marginLeft: '20px'}}>
        <ListItemText primary={t('consultingForm.results.savingsDesc') + ' ' + savings + ' ' + t('consultingForm.results.perMonth')} />
      </Box>
      <List>
        <ListItem>
          <ListItemIcon>
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText primary={t('consultingForm.results.savingsValue') + ' '  + savingYear + ' ' +
            t('consultingForm.results.year')} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <MonetizationOnIcon />
          </ListItemIcon>
          <ListItemText primary={t('consultingForm.results.equivalence') + ' ' + savingFiveYears + ' ' +t('consultingForm.results.fiveYears')} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary={t('consultingForm.results.or') + ' ' + savingTenYears + ' '+t('consultingForm.results.tenYears')} />
        </ListItem>
      </List>
    </>
  );
}

export const InvestmentResult:React.FC<InvestmentResultProps> = ({
  value,
  onChange,
  investmentTen,
  investmentTwenty,
  investmentThirty 
}) => {
  return(
  <>
    <Box sx={{width: '30%'}}>
      <FormRateInput value={value} onChange={onChange} />
    </Box>
    <Box sx={{marginLeft: '20px'}}>
      <ListItemText primary={t('consultingForm.results.with') + ' '  + value + '% ' + t('consultingForm.results.annual')} />
    </Box>
    <List>
        <ListItem>
          <ListItemIcon>
            <LocalAtmIcon />
          </ListItemIcon>
          <ListItemText primary={t('consultingForm.results.willBe') + ' ' + investmentTen + ' ' +
            t('consultingForm.results.inTenYears')} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PriceChangeIcon />
          </ListItemIcon>
          <ListItemText primary={t('consultingForm.results.willBe') + ' ' + investmentTwenty + ' ' +t('consultingForm.results.inTwentyYears')} />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PriceCheckIcon />
          </ListItemIcon>
          <ListItemText primary={t('consultingForm.results.willBe') + ' ' + investmentThirty + ' '+t('consultingForm.results.inThirtyYears')} />
        </ListItem>
      </List>
  </>
  );
}