import { Box, Button, FormControl, InputLabel, MenuItem, Select, SvgIconOwnProps, TextField, Typography } from "@mui/material"
import { t } from "i18next"
import React from "react"
import { ControlledFormGenericProps, DearUserProps, FormDropdownProps, FormGenericProps, IconBoxProps } from "./interfaces/IConsultingFormAtoms"
import { Controller } from "react-hook-form"

export const TitleForm = () => {
    return (
        <Typography variant="h1" sx={{
            fontSize: '2rem',
            fontWeight: 'bold',
            paddingLeft: '8px',
            paddingRight: '5px',
            paddingTop: '10px'
        }}>
            {t('consultingForm.title')}
        </Typography>
    )
}

export const DescriptionForm = () => {
    return (
        <Typography variant="h3" sx={{
            fontSize: '1rem',
            paddingLeft: '8px',
            paddingRight: '5px',
            paddingTop: '10px'
        }}>
            {t('consultingForm.description')}
        </Typography>
    )
}

export const FormNameInput: React.FC<FormGenericProps> = ({
    value,
    onChange,
    errors,
    register
}) => {
    return (
        <TextField
            label={t('consultingForm.name.label')}
            variant="outlined"
            required
            value={value}
            fullWidth
            {...register('name')}
            onChange={(e) => {
                onChange(e, 'name');
            }}
            error={errors.name?.type !== undefined}
            helperText={errors.name && t(errors.name.message)}
        />
    );
};

export const FormIncomeInput: React.FC<FormGenericProps> = ({
    value,
    onChange,
    errors,
    register
}) => {
    return (
        <TextField
            label={t('consultingForm.income.label')}
            variant="outlined"
            required
            value={value}
            fullWidth
            {...register('income')}
            onChange={(e) => {
                onChange(e, 'income');
            }}
            error={errors.income?.type !== undefined}
            helperText={errors.income && t(errors.income.message)}
        />
    );
};

export const CurrencyTypeList: React.FC<FormDropdownProps> = ({
    control,
    value,
    errors,
    options
}) => {

    const generateSingleOptions = () => {
        return options.map((option: any) => {
            return (
                <MenuItem key={option.id} value={option.code}>
                    {t(option.value)}
                </MenuItem>
            );
        });
    };
    return (
        <FormControl variant="outlined" fullWidth required>
            <InputLabel
                id="currencyType-label"
                sx={{ color: errors.currencyType?.type !== undefined ? 'red' : 'inherit' }}
            >
                {t('consultingForm.currencyTypes.label')}
            </InputLabel>
            <Controller
                name="currencyType"
                control={control}
                defaultValue={value}
                rules={{ required: true }}
                render={({ field }) => (
                    <Select
                        labelId="currencyType-label"
                        label={t('consultingForm.currencyTypes.label')}
                        error={errors.currencyType?.type !== undefined}
                        {...field}
                    >
                        {generateSingleOptions()}
                    </Select>
                )}
            />

            {errors.currencyType && <Typography variant="caption" sx={{ color: 'red' }}>{t(errors.currencyType.message)}</Typography>}
        </FormControl>
    )
}


export const FormOptions: React.FC<FormDropdownProps> = ({
    control,
    value,
    errors,
    options
}) => {

    const generateSingleOptions = () => {
        return options.map((option: any) => {
            return (
                <MenuItem key={option.title} value={option.title}>
                    {option.title}
                </MenuItem>
            );
        });
    };
    return (
        <FormControl variant="outlined" fullWidth required>
            <InputLabel
                id="options-label"
                sx={{ color: errors.option?.type !== undefined ? 'red' : 'inherit' }}
            >
                {t('consultingForm.options.label')}
            </InputLabel>
            <Controller
                name="option"
                control={control}
                defaultValue={value}
                rules={{ required: true }}
                render={({ field }) => (
                    <Select
                        labelId="options-label"
                        label={t('consultingForm.options.label')}
                        error={errors.option?.type !== undefined}
                        {...field}
                    >
                        {generateSingleOptions()}
                    </Select>
                )}
            />

            {errors.option && <Typography variant="caption" sx={{ color: 'red' }}>{t(errors.option.message)}</Typography>}
        </FormControl>
    )
}


export const FormButtons = () => {

    return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" mt={1}>
            <Button type="submit" variant="contained" color="primary" style={{ color: 'white', marginRight: '16px' }} >
                {t('consultingForm.button.label')}
            </Button>
        </Box>
    )
}

export const ResultTitle = () => {
    return (
        <Typography variant="h1" sx={{
            fontSize: '2rem',
            fontWeight: 'bold',
            paddingLeft: '8px',
            paddingRight: '5px',
            paddingTop: '10px'
        }}>
            {t('consultingForm.results.title')}
        </Typography>
    )
}

export const DearUser: React.FC<DearUserProps> = ({
    name
}) => {
    return (
        <Typography variant="h1" sx={{
            fontSize: '2rem',
            fontWeight: 'bold',
            paddingLeft: '8px',
            paddingRight: '5px',
            paddingTop: '10px'
        }}>
            {t('consultingForm.results.dear') + " " + name + ": "}
        </Typography>
    )
}

export const ResultTitleForBase = () => {
    return (
        <Typography variant="h1" sx={{
            fontSize: '2rem',
            fontWeight: 'bold',
            paddingLeft: '8px',
            paddingRight: '5px',
            paddingTop: '10px'
        }}>
            {t('consultingForm.results.title2')}
        </Typography>
    )
}

export const ResultTitleForSavings = () => {
    return (
        <Typography variant="h1" sx={{
            fontSize: '2rem',
            fontWeight: 'bold',
            paddingLeft: '8px',
            paddingRight: '5px',
            paddingTop: '10px'
        }}>
            {t('consultingForm.results.savingsTitle')}
        </Typography>
    )
}

export const ResultTitleForInvestment = () => {
    return (
        <Typography variant="h1" sx={{
            fontSize: '2rem',
            fontWeight: 'bold',
            paddingLeft: '8px',
            paddingRight: '5px',
            paddingTop: '10px'
        }}>
            {t('consultingForm.results.investmentTitle')}
        </Typography>
    )
}

export const FormRateInput: React.FC<ControlledFormGenericProps> = ({
    value,
    onChange,
}) => {
    return (
        <TextField
            label={t('consultingForm.rate.label')}
            variant="outlined"
            required
            value={value}
            fullWidth
            onChange={(e) => {
                onChange(e, 'rate');
            }}
        />
    );
};

export const LoginIconBox: React.FC<IconBoxProps> = ({
    value,
    handleMouseEnter,
    handleMouseLeave,
    link,
    children
}) => {
    const handleClick = () => {
        window.location.href = link;
    };

    return (
        <Box
            sx={{
                width: '120px',
                height: '40px',
                borderRadius: '20px',
                border: '1px solid black',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                overflow: 'hidden',
                mr: 1,
                cursor: 'pointer',
            }}
            onClick={handleClick}
            onMouseEnter={() => handleMouseEnter(value)}
            onMouseLeave={() => handleMouseLeave(value)}
        >
            {children}
        </Box>
    )
}

export const TikTokIconSvg: React.FC<SvgIconOwnProps> = ({ ...props }) => {
    return (
        <Box component="svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="30px"
            height="30px"
            {...props}
        >
            <Box component="path" d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
        </Box>
    );
}