import { Box, useMediaQuery } from "@mui/material";
import React from "react"
import { ConsultingFormHeader } from "../molecules/ConsultingFormMolecules";
import { DearUser, FormButtons, FormIncomeInput, FormNameInput, FormOptions, ResultTitle, ResultTitleForBase, ResultTitleForInvestment, ResultTitleForSavings } from "../atoms/ConsultingFormAtoms";
import { ConsultingFormTemplateProps, ResultTemplateProps } from "./interfaces/IConsultingFormTemplate";
import { BudgetAllocationListOrganism, BudgetGuidelines, GeneralDesc, InvestmentResult, SavingDesc } from "../organisms/ConsultingFormOrganisms";

export const ConsultingFormTemplate: React.FC<ConsultingFormTemplateProps> = ({
    name,
    onChange,
    errors,

    control,
    income,

    option,
    options,

    register
}) => {
    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');

    return (
        <Box>
            <Box sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                <ConsultingFormHeader />
            </Box>

            {isDesktop && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '16px', gap: 2 }}>
            <FormNameInput value={name} onChange={onChange} errors={errors} register={register} />
            <FormIncomeInput value={income} onChange={onChange} errors={errors} register={register} />
            <FormOptions control={control} value={option} errors={errors} options={options} />
            <FormButtons />
          </Box>
        </>
      )}

      {isTablet && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '16px', gap: 2 }}>
            <FormNameInput value={name} onChange={onChange} errors={errors} register={register} />
            <FormIncomeInput value={income} onChange={onChange} errors={errors} register={register} />
          </Box>
          <Box sx={{ paddingBottom: '16px' }}>
            <FormOptions control={control} value={option} errors={errors} options={options} />
          </Box>
          <Box sx={{ paddingBottom: '16px' }}>
            <FormButtons />
          </Box>
        </>
      )}

      {!isDesktop && !isTablet && (
        <>
          <Box sx={{ paddingBottom: '16px' }}>
            <FormNameInput value={name} onChange={onChange} errors={errors} register={register} />
          </Box>
          <Box sx={{ paddingBottom: '16px' }}>
            <FormIncomeInput value={income} onChange={onChange} errors={errors} register={register} />
          </Box>
          <Box sx={{ paddingBottom: '16px' }}>
            <FormOptions control={control} value={option} errors={errors} options={options} />
          </Box>
          <Box sx={{ paddingBottom: '16px' }}>
            <FormButtons />
          </Box>
        </>
      )}
        </Box>
    );
}

export const ResultTemplate:React.FC<ResultTemplateProps> = ({
    income,
    rule,
    needs,
    wants,
    savings,
    rentalValue,
    name,
    savingYear,
    savingFiveYears,
    savingTenYears,
    rate,
    onChange,
    investmentTen,
    investmentTwenty,
    investmentThirty
}) => {
    return (
        <>
            <Box sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                <DearUser name={name} />
            </Box>
            <Box sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                <GeneralDesc income={income} rule={rule}  />
            </Box>
            <Box sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                <ResultTitle />
            </Box>
            <Box sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                <BudgetAllocationListOrganism needs={needs} wants={wants} savings={savings} />
            </Box>
            <Box sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                <ResultTitleForBase />
            </Box>
            <Box sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                <BudgetGuidelines rentalValue={rentalValue} needs={needs} wants={wants} />
            </Box>
            <Box sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                <ResultTitleForSavings />
            </Box>
            <Box sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                <SavingDesc savings={savings} savingYear={savingYear} savingFiveYears={savingFiveYears} savingTenYears={savingTenYears} />
            </Box>
            <Box sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
              <ResultTitleForInvestment />
            </Box>
            <Box sx={{ paddingTop: '16px', paddingBottom: '16px'}}>
              <InvestmentResult value={rate} onChange={onChange} investmentTen={investmentTen} investmentTwenty={investmentTwenty} 
                investmentThirty={investmentThirty} />
            </Box>
        </>
    )
}