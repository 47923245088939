import { CurrencyType } from "../CurrencyTypeData/CurrencyType";

export const CURRENCY_TYPES: CurrencyType[] = [
    {
        id: '1',
        code: 'USD',
        value: 'consultingForm.currencyTypes.usd',
        symbol: '$'
    },
    {
        id: '2',
        code: 'EUR',
        value: 'consultingForm.currencyTypes.eur',
        symbol: '€'
    },
    {
        id: '3',
        code: 'JPY',
        value: 'consultingForm.currencyTypes.jpy',
        symbol: '¥'
    },
    {
        id: '4',
        code: 'GBP',
        value: 'consultingForm.currencyTypes.gbp',
        symbol: '£'
    },
    {
        id: '5',
        code: 'PEN',
        value: 'consultingForm.currencyTypes.pen',
        symbol: 'S/'
    }
];
