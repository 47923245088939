import React, { ChangeEvent } from "react";
import { Box, Card, CardContent, Grid, Theme, useMediaQuery } from "@mui/material";
import { ConsultingFormTemplate, ResultTemplate } from "../templates/ConsultingFormTemplate";
import { useForm } from "react-hook-form";
import { schema } from "../util/JoiSchemas/ConsultingFormSchema";
import { joiResolver } from '@hookform/resolvers/joi';
import { OPTIONS } from "../util/DropdownData/Options";
import { calcBudget, formatCurrency, generateFV } from "../logic/BudgetLogic";
import { CurrencyTypeList } from "../atoms/ConsultingFormAtoms";
import { CURRENCY_TYPES } from "../util/DropdownData/CurrencyTypes";
import { useTranslation } from "react-i18next";

interface ConsultingFormProps{
    isResultAvailable: boolean;
    handleSetIsResultAvailable: (value: boolean) => void;
}

export function ConsultingForm({
    isResultAvailable, 
    handleSetIsResultAvailable
}: ConsultingFormProps) {

    const [name, setName] = React.useState('');
    const [currencyType, setCurrencyType] = React.useState('PEN');
    const [income, setIncome] = React.useState('');
    const [option, setOption] = React.useState('');
    const [rate, setRate] = React.useState("7.0");

    const isWideScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    const [results, setResults] = React.useState({
        income: '',
        rule: '',
        needsValue: 0,
        wantsValue: 0,
        savingsValue: 0,
        rentalValue: 0,
        savingsYear: 0,
        savingsFiveYears: 0,
        savingsTenYears: 0,
        code: '',

        needsToString: '',
        wantsToString: '',
        savingsToString: '',
        rentalValueToString: '',

        savingsYearString: '',
        savingsFiveYearsString: '',
        savingsTenYearsString: '',
    });

    const investmentTen = generateFV(Number(rate), results.savingsValue, 120);
    const investmentTwenty = generateFV(Number(rate), results.savingsValue, 240);
    const investmentThirty = generateFV(Number(rate), results.savingsValue, 360);

    const { t, i18n } = useTranslation();

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string) => {
        const { value } = event.target;

        if (fieldName === 'income' && isNaN(Number(value))) {
            return;
        }

        if (fieldName === 'rate' && isNaN(Number(value))) {
            return;
        }

        switch (fieldName) {
            case 'name':
                setName(value);
                break;
            case 'income':
                setIncome(value);
                break;
            case 'rate':
                setRate(value);
                break;
            default:
                break;
        }
    };

    const { register, handleSubmit, control, formState: { errors }, getValues, watch } = useForm({
        resolver: joiResolver(schema)
    });

    const investmentToString = {
        investmentTen: results.code === '' ? '' : formatCurrency(watch('currencyType'), investmentTen),
        investmentTwenty: results.code === '' ? '' : formatCurrency(watch('currencyType'), investmentTwenty),
        investmentThirty: results.code === '' ? '' : formatCurrency(watch('currencyType'), investmentThirty)
    }

    const onSubmit = (data: any) => {
        handleSetIsResultAvailable(false);

        const optionValue = OPTIONS.find((optionValue) => optionValue.title === data.option);

        const resultsValue = calcBudget(data.income, optionValue);
        resultsValue.code = data.currencyType;

        resultsValue.income = formatCurrency(data.currencyType, data.income);
        resultsValue.rule = data.option;

        resultsValue.needsToString = formatCurrency(data.currencyType, resultsValue.needsValue);
        resultsValue.wantsToString = formatCurrency(data.currencyType, resultsValue.wantsValue);
        resultsValue.savingsToString = formatCurrency(data.currencyType, resultsValue.savingsValue);

        resultsValue.rentalValueToString = formatCurrency(data.currencyType, resultsValue.rentalValue);

        resultsValue.savingsYearString = formatCurrency(data.currencyType, resultsValue.savingsYear);
        resultsValue.savingsFiveYearsString = formatCurrency(data.currencyType, resultsValue.savingsFiveYears);
        resultsValue.savingsTenYearsString = formatCurrency(data.currencyType, resultsValue.savingsTenYears);

        setResults(resultsValue);

        handleSetIsResultAvailable(true);
    }

    const resultsToString = {
        incomeString: !results.code || !results.income ? '' : formatCurrency(watch('currencyType'), getValues('income')),
        needsToString: !results.code ? '' : formatCurrency(watch('currencyType'), results.needsValue),
        wantsToString: !results.code ? '' : formatCurrency(watch('currencyType'), results.wantsValue),
        savingsToString: !results.code ? '' : formatCurrency(watch('currencyType'), results.savingsValue),
        rentalValueToString: !results.code ? '' : formatCurrency(watch('currencyType'), results.rentalValue),

        savingsYearString: !results.code ? '' : formatCurrency(watch('currencyType'), results.savingsYear),
        savingsFiveYearsString: !results.code ? '' : formatCurrency(watch('currencyType'), results.savingsFiveYears),
        savingsTenYearsString: !results.code ? '' : formatCurrency(watch('currencyType'), results.savingsTenYears),
    }

    return (
        <Box
            sx={{
                maxWidth: '950px',
                margin: '0 auto',
                '@media (max-width: 1023px)': {
                    marginLeft: '10px',
                    marginRight: '10px',
                },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
            }}
        >
            <Box
                sx={{
                    position: !isWideScreen || isResultAvailable ? 'relative' : 'fixed',
                    top: 10,
                    paddingBottom: 5,
                    left: !isWideScreen || isResultAvailable ? 0 : '50%',
                    width: '250px',
                    textAlign: 'center',
                    transform: !isWideScreen || isResultAvailable ? 'none' : 'translateX(-50%)',
                }}
            >
                <CurrencyTypeList control={control} value={currencyType} errors={errors} options={CURRENCY_TYPES} />
            </Box>
            <Grid container justifyContent="center" alignItems="center">
                <Card elevation={3} sx={{ borderRadius: '20px', width: '100%', marginBottom: '20px' }}>
                    <CardContent sx={{ '&>*:not(:last-child)': { marginBottom: '15px' } }}>
                        <Grid item component="form" onSubmit={handleSubmit(onSubmit)}>


                            <ConsultingFormTemplate name={name} onChange={handleChange} errors={errors} control={control}
                                income={income} option={option} options={OPTIONS} register={register} />

                            {isResultAvailable && (
                                <ResultTemplate needs={resultsToString.needsToString} wants={resultsToString.wantsToString} savings={resultsToString.savingsToString}
                                    rentalValue={resultsToString.rentalValueToString} name={errors.name?.type !== undefined ? '' : getValues('name')}
                                    income={resultsToString.incomeString} rule={results.rule} savingYear={resultsToString.savingsYearString} savingFiveYears={resultsToString.savingsFiveYearsString}
                                    savingTenYears={resultsToString.savingsTenYearsString} rate={rate} onChange={handleChange}
                                    investmentTen={investmentToString.investmentTen} investmentTwenty={investmentToString.investmentTwenty}
                                    investmentThirty={investmentToString.investmentThirty} />
                            )}


                        </Grid>
                    </CardContent>
                </Card>

            </Grid>

        </Box>
    )
}