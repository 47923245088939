import React from "react";
import { HomeFooterProps, LayoutProps } from "./interfaces/IConsultingFormTemplate";
import { Box, Link, SelectChangeEvent, Theme, Typography, useMediaQuery } from "@mui/material";
import { LoginIconBox, TikTokIconSvg } from "../atoms/ConsultingFormAtoms";
import { Facebook, Instagram, Mail, Twitter } from "@mui/icons-material";
import { theme } from "..";
import { t } from "i18next";
import { ChangeLanguageGridForHome } from "../organisms/ConsultingFormOrganisms";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

const HomeFooter: React.FC<HomeFooterProps> = ({
    handleMouseEnter,
    handleMouseLeave,
    isHoveredTwitter,
    isHoveredInstagram,
    isHoveredFacebook,
    isHoveredMail,
    isHoveredTiktok,
    currentLanguage,
    handleChangeLanguage,
    isResultAvailable,
}) => {

    const isWideScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');

    return (
        <Box
            component='footer'
            sx={{
                color: 'black',
                textAlign: 'center',
                paddingLeft: 2,
                paddingRight: 2,
                position: isResultAvailable || !isWideScreen ? 'relative' : 'absolute',
                bottom: 0,
                width: '90%',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '2px',
                    background: 'linear-gradient(to right, transparent, #b8c2cc, transparent)',
                }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1, paddingBottom: 2 }}>
                <Typography variant="body1" sx={{ marginRight: 2, marginTop: isDesktop && !isTablet ? 1 : 0, textAlign: 'center' }}>
                    {t('footer.followUs')}
                </Typography>

                <LoginIconBox value={'tiktok'} handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave}
                    link={"https://www.tiktok.com/@lucy.peru"}
                    children={<TikTokIconSvg sx={{ fill: isHoveredTiktok ? theme.palette.primary.main : 'black', fontSize: '28px', zIndex: 2 }} />} />

                <LoginIconBox value={'twitter'} handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave}
                    link={"https://twitter.com/AppLucyPe"}
                    children={<Twitter sx={{ color: isHoveredTwitter ? theme.palette.primary.main : 'black', fontSize: '28px', zIndex: 2 }} />} />

                <LoginIconBox value={'facebook'} handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave}
                    link={"https://www.facebook.com/app.lucy.pe/"}
                    children={<Facebook sx={{ color: isHoveredFacebook ? theme.palette.primary.main : 'black', fontSize: '28px', zIndex: 2 }} />} />

                <LoginIconBox value={'instagram'} handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave}
                    link={"https://www.instagram.com/app.lucy.pe"}
                    children={<Instagram sx={{ color: isHoveredInstagram ? theme.palette.primary.main : 'black', fontSize: '28px', zIndex: 2 }} />} />

                <LoginIconBox value={'mail'} handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave}
                    link={"mailto:contacto@lucy.pe"}
                    children={<Mail sx={{ color: isHoveredMail ? theme.palette.primary.main : 'black', fontSize: '28px', zIndex: 2 }} />} />

            </Box>
            <Typography variant="body2">
                © Copyright 2023. {t('footer.developed')}{' '}
                <Link
                    href="https://www.boushtech.com"
                    color="inherit"
                    underline="none"
                    style={{ fontWeight: 'bold' }}
                >
                    BoushTech, Inc.
                </Link>
            </Typography>
            <ChangeLanguageGridForHome isWideScreen={false} currentLanguage={currentLanguage} handleChangeLanguage={handleChangeLanguage} />
        </Box>
    );
};

export const Layout:React.FC<LayoutProps> = ({isResultAvailable}) => {

    const [isHoveredFacebook, setIsHoveredFacebook] = React.useState(false);
    const [isHoveredTwitter, setIsHoveredTwitter] = React.useState(false);
    const [isHoveredInstagram, setIsHoveredInstagram] = React.useState(false);
    const [isHoveredMail, setIsHoveredMail] = React.useState(false);
    const [isHoveredTiktok, setIsHoveredTiktok] = React.useState(false);

    const handleMouseEnter = React.useCallback((logoType: string) => {
        switch (logoType) {
            case 'twitter':
                setIsHoveredTwitter(true);
                break;
            case 'facebook':
                setIsHoveredFacebook(true);
                break;
            case 'instagram':
                setIsHoveredInstagram(true);
                break;
            case 'mail':
                setIsHoveredMail(true);
                break;
            case 'tiktok':
                setIsHoveredTiktok(true);
                break;
            default:
                break;
        }
    }, []);

    const handleMouseLeave = React.useCallback((logoType: string) => {
        switch (logoType) {
            case 'twitter':
                setIsHoveredTwitter(false);
                break;
            case 'facebook':
                setIsHoveredFacebook(false);
                break;
            case 'instagram':
                setIsHoveredInstagram(false);
                break;
            case 'mail':
                setIsHoveredMail(false);
                break;
            case 'tiktok':
                setIsHoveredTiktok(false);
                break;
            default:
                break;
        }
    }, []);

    const { t, i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = React.useState(i18n.language);

    const handleChangeLanguage = React.useCallback((event: SelectChangeEvent<string>) => {
        const newLanguage = event.target.value;
        setCurrentLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
    }, []);

    return (
        <>
            <Outlet />
            <Box
                sx={{
                    marginTop: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <HomeFooter handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} isHoveredTwitter={isHoveredTwitter}
                    isHoveredInstagram={isHoveredInstagram} isHoveredFacebook={isHoveredFacebook} isHoveredMail={isHoveredMail}
                    isHoveredTiktok={isHoveredTiktok} currentLanguage={currentLanguage} handleChangeLanguage={handleChangeLanguage} 
                    isResultAvailable={isResultAvailable} />
            </Box>
        </>
    );
};