export const OPTIONS:any[] = [
    {
      title: "50/30/20",
      description:
        "50% of your income goes to your needs, 30% to your wants and 20% to your savings",
      needs: 0.5,
      wants: 0.3,
      savings: 0.2,
    },
    {
      title: "60/20/20",
      description:
        "60% of your income goes to your needs, 20% to your wants and 20% to your savings",
      needs: 0.6,
      wants: 0.2,
      savings: 0.2,
    },
    {
      title: "70/20/10",
      description:
        "70% of your income goes to your needs, 20% to your wants and 10% to your savings",
      needs: 0.7,
      wants: 0.2,
      savings: 0.1,
    },
    {
      title: "80/15/5",
      description:
        "80% of your income goes to your needs, 15% to your wants and 5% to your savings",
      needs: 0.8,
      wants: 0.15,
      savings: 0.05,
    },
];