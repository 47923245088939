import Joi from "joi";

export const schema = Joi.object({
    name: Joi.string().min(2).max(50).required()
        .messages({
            'string.base': `consultingForm.name.errors.base`,
            'string.min': `consultingForm.name.errors.min`,
            'string.max': `consultingForm.name.errors.max`,
            'any.required': `consultingForm.name.errors.required`,
        }),

    currencyType: Joi.string()
        .required()
        .label('CurrencyType')
        .messages({
            'string.empty': ''
        }),

    income: Joi.number().precision(2).positive().required()
        .messages({
            'number.base': `consultingForm.income.errors.base`,
            'number.precision': `consultingForm.income.errors.precision`,
            'number.positive': `consultingForm.income.errors.positive`,
            'any.required': `consultingForm.income.errors.required`,
            'number.unsafe': 'consultingForm.income.errors.unsafe'
        }),

    option: Joi.string()
        .required()
        .label('Options')
        .messages({
            'string.empty': 'consultingForm.options.errors.empty'
        }),

});