import React from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const HomeTemplate = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/presupuesto-ideal');
  };

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Paper sx={{ padding: 4, textAlign: 'center' }}>
        <Typography variant="h4" sx={{ marginBottom: 4 }}>
          Lucy Consultant, Inc.
        </Typography>
        <Button variant="contained" color="primary" style={{ color: 'white' }} onClick={handleButtonClick}>
          {t('consultingForm.title')}
        </Button>
      </Paper>

    </Box>
  );
};