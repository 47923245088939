import React from 'react';
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { ConsultingForm } from './screens/ConsultingForm';
import { HomeScreen } from './screens/HomeScreen';
import { Layout } from './templates/LayoutTemplate';

function App() {

  const [isResultAvailable, setIsResultAvailable] = React.useState(false);

  const handleSetIsResultAvailable = (value: boolean) => {
    setIsResultAvailable(value);
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path='/' element={<Layout isResultAvailable={isResultAvailable} />}>
        <Route index element={<HomeScreen />} />
        <Route path='/presupuesto-ideal' element={<ConsultingForm isResultAvailable={isResultAvailable}
          handleSetIsResultAvailable={handleSetIsResultAvailable} />} />
      </Route>
      
    )
  );

  return (
    <RouterProvider router={router} />
  );
}

export default App;
